<template>
  <div class="error403">
    <error :code="403" title="哎呀，出错了！您没有访问的页面的权限…"></error>
  </div>
</template>

<script>
import Error from "./components/error.vue";

export default {
  components: {
    Error,
  },
};
</script>
